import React from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import Toastr from 'reactjs-toastr';
import { HIDE_LOADING, SHOW_LOADING } from "../../../types";
import formatDate from "../../../utils/formatDate";
import { Link } from "react-router-dom";
import toJSON from "../../../utils/formToJson";

class Dashboard extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			statistics: {
				customerCount: 0,
				courierCount: 0,
				totalPackages: 0,
				deliveredLastMonth: 0
			},
			packages: [],
			couriers: [],
		};
		this.openAssignModal = this.openAssignModal.bind( this );
		this.assignFormSubmit = this.assignFormSubmit.bind( this );
	}

	componentDidMount() {
		var bootstrapScript = document.createElement( 'script' );
		bootstrapScript.src = "/assets/js/bootstrap.min.js";
		bootstrapScript.type = "text/javascript";
		document.body.appendChild( bootstrapScript );

		axios.get( '/dashboard/statistics/' )
			.then( response => {
				if ( response.status === 200 ) {
					this.setState( {
						statistics: response.data,
					} );
				}
			} )
			.catch( error => {
				if ( error && error.response ) {
					Toastr.error( error.response.data.message, 'Sorry' );
				}
			} );

		this.props.dispatch( {
			type: SHOW_LOADING,
		} );
		axios.get( '/deliveries/?status=Pending' )
			.then( response => {
				if ( response.status === 200 ) {
					this.setState( {
						packages: response.data
					} );
				}
			} )
			.catch( error => {
				if ( error && error.response ) {
					Toastr.error( error.response.data.message, 'Sorry' );
				}
			} )
			.finally( () => {
				this.props.dispatch( {
					type: HIDE_LOADING,
				} );
			} );

		document.querySelector( '.modal' ).addEventListener( 'click', ( event ) => {
			const closeElement = document.createElement( 'span' );
			closeElement.setAttribute( 'aria-hidden', 'true' );
			closeElement.innerText = 'x';
			if ( event.target.classList.contains( 'close-modal-button' ) ) {

				// get modals
				const modals = document.getElementsByClassName( 'modal' );

				// on every modal change state like in hidden modal
				for ( let i = 0; i < modals.length; i ++ ) {
					modals[i].classList.remove( 'show' );
					modals[i].setAttribute( 'aria-hidden', 'true' );
					modals[i].setAttribute( 'style', 'display: none' );
				}

				// get modal backdrops
				const modalsBackdrops = document.getElementsByClassName( 'modal-backdrop' );

				// remove every modal backdrop
				for ( let i = 0; i < modalsBackdrops.length; i ++ ) {
					document.body.removeChild( modalsBackdrops[i] );
				}
			}
		} )
	}

	openAssignModal( event ) {
		// get modal
		const modal = document.getElementById( "assign-to-courier-modal" );

		modal.classList.add( 'show' );
		modal.setAttribute( 'aria-hidden', 'false' );
		modal.setAttribute( 'style', 'display: block' );
		const backDrop = document.body.appendChild( document.createElement( 'div' ) );
		backDrop.classList.add( 'modal-backdrop' );
		backDrop.classList.add( 'fade' );
		backDrop.classList.add( 'show' );

		var delivery_id = event.target.getAttribute( 'data-delivery-id' );
		document.querySelector( 'input[name=delivery_id]' ).value = delivery_id;
		this.props.dispatch( {
			type: SHOW_LOADING
		} );
		axios.get( '/users/?roles=ROLE_COURIER' )
			.then( response => {
				if ( response.status === 200 ) {
					this.setState( {
						couriers: response.data
					} )
				}
			} )
			.catch( error => {
				if ( error && error.response ) {
					Toastr.error( error.response.data.message, 'Sorry' );
				}
			} )
			.finally( () => {
				this.props.dispatch( {
					type: HIDE_LOADING
				} );
			} );
	}

	assignFormSubmit( event ) {
		event.preventDefault();
		const form = document.getElementById( 'assign-form' );
		const formData = toJSON( form );
		this.props.dispatch( {
			type: SHOW_LOADING
		} );
		axios.put( '/deliveries/' + formData.delivery_id + '/', formData )
			.then( response => {
				if ( response.status === 200 ) {
					document.getElementById( "status-" + formData.delivery_id ).innerHTML = response.data.resource.status;
					document.getElementById( "assigned-to-" + formData.delivery_id ).innerHTML = response.data.resource.courier.fullname;
					// get modal
					const modal = document.getElementById( "assign-to-courier-modal" );

					// change state like in hidden modal
					modal.classList.remove( 'show' );
					modal.setAttribute( 'aria-hidden', 'true' );
					modal.setAttribute( 'style', 'display: none' );

					// get modal backdrop
					const modalBackdrops = document.getElementsByClassName( 'modal-backdrop' );

					// remove opened modal backdrop
					document.body.removeChild( modalBackdrops[0] );
				}
			} )
			.catch( error => {
				if ( error && error.response ) {
					Toastr.error( error.response.data.message, 'Sorry' );
				}
			} )
			.finally(
				this.props.dispatch( {
					type: HIDE_LOADING
				} )
			);
	}

	render() {
		return (
			<div className="container">
				<div className="page-content">
					<div className="page-content-header">
						<div className="clearfix"></div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<h3 className="text-center">Statistics</h3>
							<div className="cards-wrapper">
								<div className="card">
									<Link to='/packages'>
										<div>
											<img src="assets/img/icons/packages.svg" alt="" />
										</div>
										<div className="card-body">
											<h1 className="card-title">{ this.state.statistics.totalPackages }</h1>
											<h5>Total Packages</h5>
										</div>
									</Link>
								</div>
								<div className="card">
									<div>
										<img src="assets/img/icons/last_month.svg" alt="" />
									</div>
									<div className="card-body">
										<h1 className="card-title">{ this.state.statistics.deliveredLastMonth }</h1>
										<h5>Delivered Last Month</h5>
									</div>
								</div>
								<div className="card">
									<Link to='/users'>
										<div>
											<img src="assets/img/icons/customers.svg" alt="" />
										</div>
										<div className="card-body">
											<h1 className="card-title">{ this.state.statistics.customerCount }</h1>
											<h5>Customers</h5>
										</div>
									</Link>
								</div>
								<div className="card">
									<Link to='/couriers'>
										<div>
											<img src="assets/img/icons/courier.svg" alt="" />
										</div>
										<div className="card-body">
											<h1 className="card-title">{ this.state.statistics.courierCount }</h1>
											<h5>Courier</h5>
										</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<hr />
						</div>
						<div className="col-lg-12">
							<h3 className="text-center">Awaiting Packages</h3>
							<br />
							<div className="table-wrapper">
								<table className="table table-striped table-hover">
									<thead>
									<tr>
										<th>#</th>
										<th>Pickup Date</th>
										<th>Deliver Date</th>
										<th>Assigned To</th>
										<th>Status</th>
										<th>Actions</th>
									</tr>
									</thead>
									<tbody>
									<tr></tr>
									{
										this.state.packages.map( item => {
											let pickupDate = new Date( item.pickupDate );
											let deliverDate = new Date( item.deliverDate );
											return (
												<tr id={ "package-" + item.id } key={ item.id }>
													<td>{ item.id }</td>
													<td>{ formatDate( pickupDate ) }</td>
													<td>{ formatDate( deliverDate ) }</td>
													<td className="assigned-to" id={ "assigned-to-" + item.id }>
														<span style={ { color: "darkred" } }>Not Assigned</span>
													</td>
													<td id={ "status-" + item.id }>{ item.status }</td>
													<td>
														<Link to={ "/package/" + item.id }>Details</Link>
														&nbsp;|&nbsp;
														<a href="#"
															 className="assign-to-courier"
															 data-delivery-id={ item.id }
															 onClick={ this.openAssignModal }>Assign
															To
															Courier</a>
													</td>
												</tr>
											)
										} )
									}
									</tbody>
								</table>
							</div>
						</div>
						{/*<div className="col-lg-12">*/ }
						{/*    <hr/>*/ }
						{/*</div>*/ }
						{/*<div className="col-lg-12">*/ }
						{/*    <h3 className="text-center">Last Month Deliver Counts</h3>*/ }
						{/*    <canvas id="courierChart"></canvas>*/ }
						{/*</div>*/ }
					</div>
				</div>
				<div className="modal fade close-modal-button" id="assign-to-courier-modal" tabindex="-1" role="dialog"
						 aria-labelledby="assign-to-courier-modal" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">Assign Package To Courier</h5>
								<button type="button" className="close close-modal-button" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true" className="close-modal-button">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form id="assign-form" onSubmit={ this.assignFormSubmit }>
									<input type="hidden" name="delivery_id" />
									<input type="hidden" name="status" value="Assigned To The Courier" />
									<div><b>Select Courier</b></div>
									<select name="courier" className="select-2" style={ { width: "100%" } }>
										{
											this.state.couriers.map( item => {
												return (
													<option value={ item.id } key={ item.id }>{ item.fullname }</option>
												)
											} )
										}
									</select>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary close-modal-button" data-dismiss="modal">Close
								</button>
								<button type="submit" className="btn btn-primary assign-btn" form="assign-form">Assign</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;
export default connect( mapStateToProps )( Dashboard );
