import React from 'react';
import { connect } from "react-redux";
import toJSON from "../../../utils/formToJson";
import { HIDE_LOADING, SHOW_LOADING } from "../../../types";
import axios from "axios";
import Toastr from "reactjs-toastr";
import debounce from "../../../utils/debounce";
import loadScript from "../../../utils/loadScript";

class Ship extends React.Component {
	constructor( props ) {
		super( props );
		this.onSubmit = this.onSubmit.bind( this );
		this.searchAddress = this.searchAddress.bind( this );
		this.searchContactPerson = this.searchContactPerson.bind( this );
		this.onAutoSuggestClick = this.onAutoSuggestClick.bind( this );
		this.resetState = this.resetState.bind( this );
		this.onChange = this.onChange.bind( this );
		this.onContactPersonChange = this.onContactPersonChange.bind( this );
		this.state = {
			content: [],
			// department: [],
			floor: [],
			city: [],
			zipCode: [],
			contact_persons: [],
		};
	}

	componentDidMount() {
		loadScript( '/assets/js/survey_func.js', () => {
			loadScript( '/assets/js/air.datepicker.min.js', () => {
				loadScript( '/assets/js/en.air.datepicker.min.js', () => {
					loadScript( '/assets/js/timepicker_customized.js', () => null );
				} );
			} );
		} );

		const datepickerStyle = document.createElement( 'link' );
		datepickerStyle.rel = 'stylesheet';
		datepickerStyle.href = '/assets/css/air.datepicker.min.css';
		document.head.appendChild( datepickerStyle );
	}

	onSubmit( event ) {
		event.preventDefault();
		var form = document.getElementById( "wrapped delivery-form" );
		var data = toJSON( form );
		this.props.dispatch( {
			type: SHOW_LOADING
		} );
		const user = JSON.parse( localStorage.getItem( 'user' ) );
		if ( user.roles.indexOf( 'ROLE_ADMIN' ) !== - 1 ) {
			if ( this.props.match.params.id ) {
				const { id } = this.props.match.params;
				data.company = id;
			}
		}
		axios.post( '/deliveries/', data ).then( response => {
			console.log( data );
			if ( response.status === 201 ) {
				Toastr.success( 'Delivery created successfully.' );
				this.props.history.push( '/' );
			} else {
				Toastr.error( 'An error occured, please try again later.', 'Sorry' );
			}
			this.props.dispatch( {
				type: HIDE_LOADING
			} );
		} ).catch( ( error ) => {
			console.log( error );
			if ( error.response ) {
				console.log( error.response );
				if ( error.response.status === 401 ) {
					console.log( 'Sorry man!' );
					Toastr.error( error.response.data.message, 'Sorry' );
				}
			}
			this.props.dispatch( {
				type: HIDE_LOADING
			} );
		} );
	}

	onAutoSuggestClick( e ) {
		e.preventDefault();
		const text = e.target.innerText;
		const field = e.target.getAttribute( 'data-field' );
		const targetElement = document.querySelector( e.target.getAttribute( 'data-target' ) );
		targetElement.value = text;
		if ( e.target.getAttribute( 'data-hidden-input' ) ) {
			const type = e.target.getAttribute( 'data-type' );
			const contactId = e.target.getAttribute( 'data-hidden-input-value' );
			document.querySelector( e.target.getAttribute( 'data-hidden-input' ) ).value = contactId;
			this.props.dispatch( {
				type: SHOW_LOADING,
			} );
			axios.post( '/addresses/search_by_contact/', { contact_person: contactId, type: type } )
				.then( response => {
					if ( response.status === 200 ) {
						let prefix = '';
						if ( type === 'sender' ) {
							prefix = 'pickup_';
						} else {
							prefix = 'delivery_';
						}
						document.querySelector( '[name=' + prefix + 'address]' ).value = response.data.content;
						// document.querySelector('[name=' + prefix + 'department]').value = response.data.department;
						document.querySelector( '[name=' + prefix + 'company_name]' ).value = response.data.companyName;
						document.querySelector( '[name=' + prefix + 'floor]' ).value = response.data.floor;
						document.querySelector( '[name=' + prefix + 'city]' ).value = response.data.city;
						document.querySelector( '[name=' + prefix + 'state]' ).value = response.data.state;
						document.querySelector( '[name=' + prefix + 'zip_code]' ).value = response.data.zipCode;
						document.querySelector( '[name=' + prefix + 'phone]' ).value = response.data.phone;
						// document.querySelector('[name=' + prefix + 'extension]').value = response.data.extension;
						document.querySelector( '[name=' + prefix + 'email]' ).value = response.data.email;
					}
				} )
				.catch( error => {
					if ( error && error.response.data.message ) {
						Toastr.error( error.response.data.message, 'Sorry' );
					}
				} )
				.finally( () => {
					this.props.dispatch( {
						type: HIDE_LOADING,
					} );
				} );
		}
		this.resetState();
	}

	resetState() {
		this.setState( {
			content: [],
			// department: [],
			floor: [],
			city: [],
			zipCode: [],
			contact_persons: [],
		} );
	}

	onChange = debounce( ( event ) => {
		if ( event.target.value != '' ) {
			const searchData = { [event.target.getAttribute( 'data-field' )]: event.target.value };
			this.setState( {
				searchData: searchData,
			} );

			this.searchAddress( searchData, event.target.dataset.field );
		} else {
			this.resetState();
		}
	}, 300 );

	onContactPersonChange = debounce( ( event ) => {
		if ( event.target.value != '' ) {
			const searchData = { name: event.target.value };
			this.setState( {
				searchData: searchData,
			} );

			this.searchContactPerson( searchData );
		} else {
			this.resetState();
		}
	}, 200 );

	searchContactPerson( searchData ) {
		axios.post( '/contact_persons/search/', searchData )
			.then( response => {
				this.setState( {
					contact_persons: response.data.items.map( item => {
						return { id: item.id, fullname: item.fullname };
					} ),
				} );
			} )
			.catch( error => {
				if ( error.response && error.response.status === 404 ) {
					this.resetState();
				}

				if ( error && error.response && error.response.status !== 404 ) {
					Toastr.error( error.response.data.message, 'Sorry' );
				}
			} )
			.finally();
	}

	searchAddress( searchData, field ) {
		axios.post( '/addresses/search/', searchData )
			.then( response => {
				this.setState( {
					[field]: response.data.map( item => {
						return item[field];
					} ),
				} );
			} )
			.catch( error => {
				if ( error.response && error.response.status === 404 ) {
					this.resetState();
				}

				if ( error && error.response && error.response.status !== 404 ) {
					Toastr.error( error.response.data.message, 'Sorry' );
				}
			} )
			.finally();
	}

	render() {
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6 content-left sticky-side-content">
						<div className="content-left-wrapper">
							<div>
								<figure><img src="assets/img/register/van-messenger.png" alt="" className="img-fluid" />
								</figure>
								<h2>Do You Need Help?</h2>
								<p>You can place and track orders, run activity reports, and even view invoices.</p>
								<a href="tel:212-481-1007" className="btn_1 rounded">Call Us</a>

							</div>
							<div className="copy">© 2019 - Manhattan Business Services</div>
						</div>
					</div>

					<div className="col-lg-6 content-right" id="start">
						<div id="wizard_container">
							<div id="top-wizard">
								<div id="progressbar"></div>
							</div>
							<form id="wrapped delivery-form" onSubmit={ this.onSubmit }>
								<div id="middle-wizard">
									<div className="step">
										<h2>Pickup Informations</h2>
										<h3 className="main_question"><strong>1/3</strong>Please fill with your
											details</h3>
										{/* Company Name */}
										<div className="form-group">
											<input type="text" name="pickup_company_name"
												   className="form-control required"
												   placeholder="Company Name" />
										</div>
										{/* Attention */}
										<div className="form-group">
											<input type="hidden" name="pickup_contact_person_id"/>
											<input type="text" name="pickup_contact_person"
												   className="form-control required" placeholder="Attention"
												   autoComplete="no" onChange={(event) => {
												event.persist();
												this.onContactPersonChange(event)
											}}
											/>
											{
												this.state.contact_persons.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.contact_persons.map(item => {
																	return (
																		<li><a href="#" data-field="content"
																			   data-target="[name=pickup_contact_person]"
																			   onClick={this.onAutoSuggestClick}
																			   data-hidden-input="[name=pickup_contact_person_id]"
																			   data-hidden-input-value={item.id}
																			   data-type="sender">{item.fullname}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* Pickup Address */}
										<div className="form-group">
											<textarea type="text"
													  name="pickup_address"
													  className="form-control required"
													  placeholder="Pickup Address"
													  data-field="content" autoComplete="no" onChange={(event) => {
												event.persist();
												this.onChange(event)
											}}></textarea>
											{
												this.state.content.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.content.map(item => {
																	return (
																		<li><a href="#" data-field="content"
																			   data-target="[name=pickup_address]"
																			   onClick={this.onAutoSuggestClick}>{item}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* Suite */}
										<div className="form-group">
											<input type="text" name="pickup_floor" className="form-control required"
												   placeholder="Suite"
												   data-field="floor" autoComplete="no" onChange={(event) => {
												event.persist();
												this.onChange(event)
											}}
											/>
											{
												this.state.floor.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.floor.map(item => {
																	return (
																		<li><a href="#"
																			   data-target="[name=pickup_floor]"
																			   onClick={this.onAutoSuggestClick}>{item}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* City */}
										<div className="form-group">
											<input type="text" name="pickup_city" className="form-control required"
												   placeholder="City"
												   data-field="city" autoComplete="no" onChange={(event) => {
												event.persist();
												this.onChange(event)
											}}
											/>
											{
												this.state.city.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.city.map(item => {
																	return (
																		<li><a href="#" data-target="[name=pickup_city]"
																			   onClick={this.onAutoSuggestClick}>{item}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* State */}
										<div className="form-group">
											<div className="styled-select clearfix">
												{/* eslint-disable-next-line no-undef */ }
												<select className="wide form-control required" name="pickup_state"
														onChange="getVals( this, 'state' )">
													<option value="">Select State</option>
													<option value="Alabama">Alabama</option>
													<option value="Alaska">Alaska</option>
													<option value="Arizona">Arizona</option>
													<option value="Arkansas">Arkansas</option>
													<option value="California">California</option>
													<option value="Colorado">Colorado</option>
													<option value="Connecticut">Connecticut</option>
													<option value="Delaware">Delaware</option>
													<option value="Florida">Florida</option>
													<option value="Georgia">Georgia</option>
													<option value="Hawaii">Hawaii</option>
													<option value="Idaho">Idaho</option>
													<option value="Illinois">Illinois</option>
													<option value="Indiana">Indiana</option>
													<option value="Iowa">Iowa</option>
													<option value="Kansas">Kansas</option>
													<option value="Kentucky">Kentucky</option>
													<option value="Louisiana">Louisiana</option>
													<option value="Maine">Maine</option>
													<option value="Maryland">Maryland</option>
													<option value="Massachusetts">Massachusetts</option>
													<option value="Michigan">Michigan</option>
													<option value="Minnesota">Minnesota</option>
													<option value="Mississippi">Mississippi</option>
													<option value="Missouri">Missouri</option>
													<option value="Montana">Montana</option>
													<option value="Nebraska">Nebraska</option>
													<option value="Nevada">Nevada</option>
													<option value="New Hampshire">New Hampshire</option>
													<option value="New Jersey">New Jersey</option>
													<option value="New Mexico">New Mexico</option>
													<option value="New York">New York</option>
													<option value="North Carolina">North Carolina</option>
													<option value="North Dakota">North Dakota</option>
													<option value="Ohio">Ohio</option>
													<option value="Oklahoma">Oklahoma</option>
													<option value="Oregon">Oregon</option>
													<option value="Pennsylvania">Pennsylvania</option>
													<option value="Rhode Island">Rhode Island</option>
													<option value="South Carolina">South Carolina</option>
													<option value="South Dakota">South Dakota</option>
													<option value="Tennessee">Tennessee</option>
													<option value="Texas">Texas</option>
													<option value="Utah">Utah</option>
													<option value="Vermont">Vermont</option>
													<option value="Virginia">Virginia</option>
													<option value="Washington">Washington</option>
													<option value="West Virginia">West Virginia</option>
													<option value="Wisconsin">Wisconsin</option>
													<option value="Wyoming">Wyoming</option>
												</select>
											</div>
										</div>
										{/* Zip Code */}
										<div className="form-group">
											<input type="text" name="pickup_zip_code" className="form-control required"
												   placeholder="Zip Code" data-field="zipCode" autoComplete="no"
												   onChange={(event) => {
													   event.persist();
													   this.onChange(event)
												   }}/>
											{
												this.state.zipCode.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.zipCode.map(item => {
																	return (
																		<li><a href="#"
																			   data-target="[name=pickup_zip_code]"
																			   onClick={this.onAutoSuggestClick}>{item}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* Phone */}
										<div className="form-group">
											<input type="text" name="pickup_phone" className="form-control required"
												   placeholder="Phone" />
										</div>
										{/* Email */}
										<div className="form-group">
											<input type="email" name="pickup_email" className="form-control required"
												   placeholder="Email" />
										</div>
										{/*<div className="form-group">*/ }
										{/*<input type="text" name="pickup_department"*/ }
										{/*       className="form-control required"*/ }
										{/*       placeholder="Department" data-field="department" autocomplete="no"*/ }
										{/*       onChange={(event) => {*/ }
										{/*           event.persist();*/ }
										{/*           this.onChange(event)*/ }
										{/*       }}/>*/ }
										{/*{*/ }
										{/*    this.state.department.length > 0 ? (*/ }
										{/*        <div className="suggestions">*/ }
										{/*            <ul>*/ }
										{/*                {*/ }
										{/*                    this.state.department.map(item => {*/ }
										{/*                        return (*/ }
										{/*                            <li><a href="#"*/ }
										{/*                                   data-target="[name=pickup_department]"*/ }
										{/*                                   onClick={this.onAutoSuggestClick}>{item}</a>*/ }
										{/*                            </li>*/ }
										{/*                        )*/ }
										{/*                    })*/ }
										{/*                }*/ }
										{/*            </ul>*/ }
										{/*        </div>*/ }
										{/*    ) : null*/ }
										{/*}*/ }
										{/*</div>*/ }
										{/*<div className="form-group">*/ }
										{/*    <input type="text" name="pickup_extension" className="form-control required"*/ }
										{/*           placeholder="Extension"/>*/ }
										{/*</div>*/ }
										<div className="form-group">
											<div id="pass-info"></div>
										</div>
									</div>
									<div className="step">
										<h2>Delivery Informations</h2>
										<h3 className="main_question"><strong>2/3</strong>Please fill with receiver
											details</h3>
										{/* Company Name */}
										<div className="form-group">
											<input type="text" name="delivery_company_name"
												   className="form-control required"
												   placeholder="Company Name" />
										</div>
										{/* Attention */}
										<div className="form-group">
											<input type="hidden" name="delivery_contact_person_id"/>
											<input type="text" name="delivery_contact_person"
												   className="form-control required" placeholder="Attention"
												   autoComplete="no" onChange={(event) => {
												event.persist();
												this.onContactPersonChange(event)
											}}
											/>
											{
												this.state.contact_persons.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.contact_persons.map(item => {
																	return (
																		<li><a href="#" data-field="content"
																			   data-target="[name=delivery_contact_person]"
																			   onClick={this.onAutoSuggestClick}
																			   data-hidden-input="[name=delivery_contact_person_id]"
																			   data-hidden-input-value={item.id}
																			   data-type="receiver">{item.fullname}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* Address */}
										<div className="form-group">
											<textarea type="text" name="delivery_address"
													  className="form-control required"
													  placeholder="Delivery Address"
													  data-field="content" autoComplete="no"
													  onChange={(event) => {
														  event.persist();
														  this.onChange(event)
													  }}></textarea>
											{
												this.state.content.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.content.map(item => {
																	return (
																		<li><a href="#" data-field="content"
																			   data-target="[name=delivery_address]"
																			   onClick={this.onAutoSuggestClick}>{item}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* Suite */}
										<div className="form-group">
											<input type="text" name="delivery_floor" className="form-control required"
												   placeholder="Suite" data-field="floor" autoComplete="no"
												   onChange={(event) => {
													   event.persist();
													   this.onChange(event)
												   }}/>
											{
												this.state.floor.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.floor.map(item => {
																	return (
																		<li><a href="#"
																			   data-target="[name=delivery_floor]"
																			   onClick={this.onAutoSuggestClick}>{item}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* City */}
										<div className="form-group">
											<input type="text" name="delivery_city" className="form-control required"
												   placeholder="City"
												   data-field="city" autoComplete="no" onChange={(event) => {
												event.persist();
												this.onChange(event)
											}}/>
											{
												this.state.city.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.city.map(item => {
																	return (
																		<li><a href="#"
																			   data-target="[name=delivery_city]"
																			   onClick={this.onAutoSuggestClick}>{item}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* State */}
										<div className="form-group">
											<div className="styled-select clearfix">
												{/* eslint-disable-next-line no-undef */ }
												<select className="wide form-control required" name="delivery_state"
														onChange="getVals( this, 'state' )">
													<option value="">Select State</option>
													<option value="Alabama">Alabama</option>
													<option value="Alaska">Alaska</option>
													<option value="Arizona">Arizona</option>
													<option value="Arkansas">Arkansas</option>
													<option value="California">California</option>
													<option value="Colorado">Colorado</option>
													<option value="Connecticut">Connecticut</option>
													<option value="Delaware">Delaware</option>
													<option value="Florida">Florida</option>
													<option value="Georgia">Georgia</option>
													<option value="Hawaii">Hawaii</option>
													<option value="Idaho">Idaho</option>
													<option value="Illinois">Illinois</option>
													<option value="Indiana">Indiana</option>
													<option value="Iowa">Iowa</option>
													<option value="Kansas">Kansas</option>
													<option value="Kentucky">Kentucky</option>
													<option value="Louisiana">Louisiana</option>
													<option value="Maine">Maine</option>
													<option value="Maryland">Maryland</option>
													<option value="Massachusetts">Massachusetts</option>
													<option value="Michigan">Michigan</option>
													<option value="Minnesota">Minnesota</option>
													<option value="Mississippi">Mississippi</option>
													<option value="Missouri">Missouri</option>
													<option value="Montana">Montana</option>
													<option value="Nebraska">Nebraska</option>
													<option value="Nevada">Nevada</option>
													<option value="New Hampshire">New Hampshire</option>
													<option value="New Jersey">New Jersey</option>
													<option value="New Mexico">New Mexico</option>
													<option value="New York">New York</option>
													<option value="North Carolina">North Carolina</option>
													<option value="North Dakota">North Dakota</option>
													<option value="Ohio">Ohio</option>
													<option value="Oklahoma">Oklahoma</option>
													<option value="Oregon">Oregon</option>
													<option value="Pennsylvania">Pennsylvania</option>
													<option value="Rhode Island">Rhode Island</option>
													<option value="South Carolina">South Carolina</option>
													<option value="South Dakota">South Dakota</option>
													<option value="Tennessee">Tennessee</option>
													<option value="Texas">Texas</option>
													<option value="Utah">Utah</option>
													<option value="Vermont">Vermont</option>
													<option value="Virginia">Virginia</option>
													<option value="Washington">Washington</option>
													<option value="West Virginia">West Virginia</option>
													<option value="Wisconsin">Wisconsin</option>
													<option value="Wyoming">Wyoming</option>
												</select>
											</div>
										</div>
										{/* Zip Code */}
										<div className="form-group">
											<input type="text" name="delivery_zip_code"
												   className="form-control required"
												   placeholder="Zip Code"
												   data-field="zipCode" autoComplete="no" onChange={(event) => {
												event.persist();
												this.onChange(event)
											}}/>
											{
												this.state.zipCode.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.zipCode.map(item => {
																	return (
																		<li><a href="#"
																			   data-target="[name=delivery_zip_code]"
																			   onClick={this.onAutoSuggestClick}>{item}</a>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												) : null
											}
										</div>
										{/* Phone */}
										<div className="form-group">
											<input type="text" name="delivery_phone" className="form-control required"
												   placeholder="Phone" />
										</div>
										{/* Email */}
										<div className="form-group">
											<input type="email" name="delivery_email" className="form-control required"
												   placeholder="Email" />
										</div>
										{/*<div className="form-group">*/ }
										{/*    <input type="text" name="delivery_department"*/ }
										{/*           className="form-control required"*/ }
										{/*           placeholder="Department" data-field="department" autocomplete="no"*/ }
										{/*           onChange={(event) => {*/ }
										{/*               event.persist();*/ }
										{/*               this.onChange(event)*/ }
										{/*           }}/>*/ }
										{/*    {*/ }
										{/*        this.state.department.length > 0 ? (*/ }
										{/*            <div className="suggestions">*/ }
										{/*                <ul>*/ }
										{/*                    {*/ }
										{/*                        this.state.department.map(item => {*/ }
										{/*                            return (*/ }
										{/*                                <li><a href="#"*/ }
										{/*                                       data-target="[name=delivery_department]"*/ }
										{/*                                       onClick={this.onAutoSuggestClick}>{item}</a>*/ }
										{/*                                </li>*/ }
										{/*                            )*/ }
										{/*                        })*/ }
										{/*                    }*/ }
										{/*                </ul>*/ }
										{/*            </div>*/ }
										{/*        ) : null*/ }
										{/*    }*/ }
										{/*</div>*/ }
										{/*<div className="form-group">*/ }
										{/*    <input type="text" name="delivery_extension"*/ }
										{/*           className="form-control required"*/ }
										{/*           placeholder="Extension"/>*/ }
										{/*</div>*/ }
										<div className="form-group">
											<div id="pass-info"></div>
										</div>
									</div>
									<div className="step submit">
										<h3 className="main_question"><strong>3/3</strong>Please fill with package
											details.</h3>
										<div className="form-group">
											<div className="inline-inputs">
												<input type="number" name="width" className="form-control required"
															 placeholder="width" />
												<input type="number" name="height"
															 className="form-control required" placeholder="height" />
												<input type="number" name="depth"
															 className="form-control required"
															 placeholder="depth" />
											</div>
										</div>
										<div className="form-group">
											<input type="text" name="weight" className="form-control required"
														 placeholder="Package Weight (lbs)" />
										</div>
										<div className="form-group">
											<input type="text" name="pickup_date" className="form-control required"
														 placeholder="Pickup Date" autoComplete="off" />
										</div>
										<div className="form-group">
											<input type="text" name="delivery_date"
														 className="form-control required" placeholder="Delivery Date"
														 autoComplete="off" />
										</div>
										<div className="form-group">
                                                <textarea type="text" name="package_details"
																													className="form-control required"
																													placeholder="Package Details"></textarea>
										</div>
										<div className="form-group">
											<div className="styled-select clearfix">
												{/* eslint-disable-next-line no-undef */ }
												<select className="wide form-control required" name="service_type"
																onChange="getVals( this, 'service_type' )">
													<option value="">Select Service Type</option>
													<option value="Bike Messenger">Bike Messenger</option>
													<option value="Van Services">Van Services</option>
													<option value="White Glove Services">White Glove Services</option>
												</select>
											</div>
										</div>
										<div className="form-group terms">
											<label className="container_check">Please accept our <a href="#"
																																							data-toggle="modal"
																																							data-target="#terms-txt">Terms
												and conditions</a>
												<input type="checkbox" name="terms" value="Yes"
															 className="required" />
												<span className="checkmark"></span>
											</label>
										</div>
									</div>
								</div>
								<div id="bottom-wizard">
									<button type="button" name="backward" className="backward">Prev</button>
									<button type="button" name="forward" className="forward">Next</button>
									<button type="submit" name="process" className="submit">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;

export default connect( mapStateToProps )( Ship );
