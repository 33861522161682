export default function formatDateForSubmit(date) {
    var result = /(\d\d)\/(\d\d)\/(\d\d\d\d) (\d\d):(\d\d) (\w)m/i.exec(date);
    if (result !== null) {
        var hours = result[4];
        if (result[6] === 'p') {
            hours = parseInt(result[4]) + 12;
        }
        var dateObject = new Date(parseInt(result[3]), parseInt(result[1]) - 1, parseInt(result[2]), parseInt(hours), parseInt(result[5]));
        return dateObject;
    }

    return null;
}