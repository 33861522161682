import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import axios from 'axios';

// Import Reducers
import userReducer from "./reducers/userReducer";
import addressReducer from "./reducers/addressReducer";
import companyReducer from "./reducers/companyReducer";
import contactPersonReducer from "./reducers/contactPersonReducer";
import packageDetailReducer from "./reducers/packageDetailReducer";
import packageReducer from "./reducers/packageReducer";
import setAuthorizationToken from "./utils/setAuthorizationToken";
import loadingReducer from "./reducers/loadingReducer";
import axiosMiddleware from "./utils/axiosMiddleware";

const rootReducer = combineReducers({
	user: userReducer,
	addresses: addressReducer,
	company: companyReducer,
	contactPersons: contactPersonReducer,
	packageDetails: packageDetailReducer,
	packages: packageReducer,
	loading: loadingReducer,
});

const store = createStore(
	rootReducer,
	{
		user: {},
		addresses: [],
		company: {},
		contactPersons: [],
		packageDetails: [],
		packages: [],
		loading: {showLoader: false},
	},
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

if (localStorage.getItem('token')) {
	setAuthorizationToken(localStorage.getItem('token'));
}

axiosMiddleware();
axios.defaults.baseURL = 'https://api.manhattanbusinessservices.com';
// axios.defaults.baseURL = 'http://localhost:8000/index.php';

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
