import React from 'react';
import axios from 'axios';
import {HIDE_LOADING, SHOW_LOADING} from "../../../../types";
import Toastr from 'reactjs-toastr';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class Companies extends React.Component {
    constructor(props) {
        super(props);
        this.deleteCompany = this.deleteCompany.bind(this);
        this.state = {
            companies: [],
        };
    }

    componentDidMount() {
        this.props.dispatch({
            type: SHOW_LOADING,
        });

        axios.get('/companies/')
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        companies: response.data.items
                    });
                }
            })
            .catch(error => {
                if (error && error.response) {
                    Toastr.error(error.response.data.message, 'Sorry');
                }
            })
            .finally(
                this.props.dispatch({
                    type: HIDE_LOADING,
                })
            );
    }

    deleteCompany(event) {
        event.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure want to delete this company?')) {
            this.props.dispatch({
                type: SHOW_LOADING,
            });
            var companyId = event.target.getAttribute('data-id');
            axios.delete('/companies/' + companyId + '/')
              .then(response => {
                  if (response.status === 200) {
                      this.setState({
                          companies: this.state.companies.filter(item => item.id != companyId)
                      });
                      Toastr.success(response.data.message, 'Success');
                  }
              })
              .catch(error => {
                  if (error && error.response) {
                      Toastr.error(error.response.data.message, 'Error');
                  }
              })
              .finally(() => {
                  this.props.dispatch({
                      type: HIDE_LOADING,
                  });
              });
        }
        return false;
    }

    deleteLink(item) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.indexOf('ROLE_ADMIN') !== -1) {
            return (
              <span>
              &nbsp;|&nbsp;
                  <a href="#" onClick={ this.deleteCompany } data-id={ item.id }>Delete</a>
          </span>
            )
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="page-content-header">
                                <h4 className="page-content-title" style={{display: "inline-block"}}>All Companies</h4>
                                <Link to="/create/company" className="btn btn-blue float-right">Create Company</Link>
                                <div className="clearfix"></div>
                            </div>
                            <div className="table-wrapper">
                                <table className="table table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr></tr>
                                    {
                                        this.state.companies.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <Link to={"/companies/" + item.id}>Details</Link>
                                                        &nbsp;|&nbsp;
                                                        <Link to={"/companies/edit/" + item.id}>Edit</Link>
                                                        &nbsp;|&nbsp;
                                                        <Link to={"/ship-now/" + item.id}>Create Package</Link>
                                                        {this.deleteLink(item)}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(Companies);
