import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import formatDateForSubmit from "../../utils/formatDateForSubmit";
import toJSON from "../../utils/formToJson";
import {HIDE_LOADING, SHOW_LOADING} from "../../types";
import axios from 'axios';
import Toastr from 'reactjs-toastr';
import loadScript from "../../utils/loadScript";

class CourierDeliver extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(event) {
        const { token } = this.props.match.params;
        event.preventDefault();
        var form = document.getElementById('complete_delivery_form');
        var formData = toJSON(form);
        formData.deliveredAt = formatDateForSubmit(formData.deliveredAt)
        formData.status = 'Delivered';
        this.props.dispatch({
            type: SHOW_LOADING,
        });
        axios.put('/deliveries/token/' + token + '/', formData)
            .then(response => {
                if (response.status == 200) {
                    Toastr.success('Package delivery completed.', 'Success 👍🏻');
                    this.props.history.push('/courier-track/' + token);
                }
            })
            .catch(error => {
                if (error.response) {
                    Toastr.error(error.response.data.message, 'Sorry');
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING,
                });
            });
    }

    componentDidMount() {
        loadScript('/assets/js/air.datepicker.min.js', () => {
            loadScript('/assets/js/en.air.datepicker.min.js', () => {
                loadScript('/assets/js/timepicker_customized.js', () => {});
            });
        });

        const datepickerStyle = document.createElement('link');
        datepickerStyle.rel = 'stylesheet';
        datepickerStyle.href = '/assets/css/air.datepicker.min.css';
        document.head.appendChild(datepickerStyle);
    }

    render() {
        return (
            <div className="container" style={{maxWidth: "800px"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="page-content-header">
                                <h4 className="page-content-title" style={{display: "inline-block"}}>Complete Package
                                    Delivery</h4>
                                <Link to="/" className="btn btn-blue float-right">Back To List</Link>
                                <div className="clearfix"></div>
                            </div>
                            <div className="table-wrapper">
                                <form id="complete_delivery_form" onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="deliveredTo">Delivered To</label>
                                        <input type="text" className="form-control" id="deliveredTo"
                                               name="deliveredTo" required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="delivered_at">Delivered At</label>
                                        <input type="datetime" className="form-control" name="deliveredAt"
                                               id="delivered_at"
                                               autocomplete="off"
                                               required/>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-success" type="submit">Complete Delivery</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(CourierDeliver);
