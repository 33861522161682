import React from 'react';
import Packages from "../Packages/Packages";
import {connect} from "react-redux";
import Dashboard from "../Dashboard/Dashboard";

class DashboardWrapper extends React.Component {
    render() {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user.roles.indexOf('ROLE_ADMIN') != -1) {
            return (
                <Dashboard />
            );
        } else {
            return (
                <Packages />
            );
        }
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(DashboardWrapper);