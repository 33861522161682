import React from 'react';
import toJSON from "../../utils/formToJson";
import { connect } from "react-redux";
import { SHOW_LOADING, HIDE_LOADING, SET_CURRENT_USER } from "../../types";
import 'reactjs-toastr/lib/toast.css';
import axios from 'axios';
import Toastr from 'reactjs-toastr';
import formatDate from "../../utils/formatDate";

class Track extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            delivery: {}
        };
    }

    onSubmit(event) {
        event.preventDefault();
        var form = document.getElementById('wrapped track-form');
        var formData = toJSON(form);
        axios.get('/deliveries/track/' + formData.trackId + '/')
            .then(response => {
                console.log(response.data);
                this.state.delivery = response.data;
                this.props.dispatch({
                    type: SHOW_LOADING,
                });
            })
            .catch(error => {
                if (error && error.response) {
                    if (error.response.status == 404) {
                        Toastr.error(error.response.data.message, 'Sorry');
                    }
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING,
                });
            });
    }

    TrackTable() {
        if (this.state.delivery.senderContact) {
            return (
                <table className="table">
                    <tbody>
                    <tr>
                        <td>Courier:</td>
                        <td>{this.state.delivery.courier ? this.state.delivery.courier.fullname : '-'}</td>
                    </tr>
                    <tr>
                        <td>Sender:</td>
                        <td>{this.state.delivery.senderContact.fullname}</td>
                    </tr>
                    <tr>
                        <td>Receiver:</td>
                        <td>{this.state.delivery.recieverContact.fullname}</td>
                    </tr>
                    <tr>
                        <td>Service Type:</td>
                        <td>{this.state.delivery.serviceType}</td>
                    </tr>
                    <tr>
                        <td>Width:</td>
                        <td>{this.state.delivery.packageDetail.width}"</td>
                    </tr>
                    <tr>
                        <td>Height:</td>
                        <td>{this.state.delivery.packageDetail.height}"</td>
                    </tr>
                    <tr>
                        <td>Depth:</td>
                        <td>{this.state.delivery.packageDetail.depth}"</td>
                    </tr>
                    <tr>
                        <td>Weight:</td>
                        <td>{this.state.delivery.packageDetail.weight}lbs</td>
                    </tr>
                    <tr>
                        <td>{this.state.delivery.status != 'Delivered' ? 'Estimated Delivery Date' : 'Delivered At'}:</td>
                        <td>{this.state.delivery.status != 'Delivered' ? formatDate(new Date(this.state.delivery.deliverDate)) : formatDate(new Date(this.state.delivery.deliveredAt))}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>{this.state.delivery.status}</td>
                    </tr>
                    <tr>
                      <td>Received By:</td>
                      <td>{this.state.delivery.deliveredTo || '-'}</td>
                    </tr>
                    </tbody>
                </table>
            )
        }

        return (<div></div>);
    }

    render() {
        return (
            <div className="container-fluid full-height">
                <div className="row row-height">
                    <div className="col-lg-6 content-left">
                        <div className="content-left-wrapper">
                            <div>
                                <figure><img src="assets/img/register/van-messenger.png" alt="" className="img-fluid" /></figure>
                                <h2>You Can Track Your Package</h2>
                                <p>The moment you place your order, you can track your package as it moves through the supply chain.
                                  Need frequent updates for your package? With your Manhattan Business Services account, you’ll have real-time status reports up to the minute!</p>
                            </div>
                            <div className="copy">© 2019 - Manhattan Business Services</div>
                        </div>
                    </div>

                    <div className="col-lg-6 content-right" id="start">
                        <div id="wizard_container">
                            <form id="wrapped track-form" onSubmit={this.onSubmit}>
                                <div id="middle-wizard">
                                    <div className="step submit">
                                        <h2>Where Is My Package?</h2>
                                        <h3 className="main_question">Please enter your delivery track number</h3>
                                        <div className="form-group">
                                            <input type="text" name="trackId" className="form-control required" placeholder="Track Number" />
                                        </div>
                                        <br />
                                        {this.TrackTable()}
                                    </div>
                                </div>
                                <div id="bottom-wizard">
                                    <button type="submit" name="process" className="submit">Submit</button>
                                </div>
                            </form>
                            <div id="top-wizard">
                                <div id="progressbar"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    loading: state.loading,
});

export default connect(mapStateToProps)(Track);
