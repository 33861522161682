import axios from 'axios';
import { Service } from 'axios-middleware';

export default function axiosMiddleware() {
    const service = new Service(axios);

    service.register({
        onResponseError(error) {
            if (error.response !== undefined && error.response.data !== undefined) {
                if (error.response.status === 401 && error.response.data === '{"code":401,"message":"Expired JWT Token"}') {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    window.location.href = '/#/login';
                }
            }
            throw error;
        }
    });
}