import React from 'react';
import { connect } from "react-redux";

class Loading extends React.Component {
	render() {
		if (this.props.loading.showLoader === true) {
			return (
				<div className="loading" style={ {
					position: "fixed",
					width: "100%",
					height: "100vh",
					background: "rgba(0, 0, 0, .6)",
					zIndex: "99999999",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					top: "-20px",
					left: "0px"
				} }>
					<img src="/assets/img/loading.svg" alt="Loading" style={ { width: "50px", height: "50px" } } />
				</div>
			);
		} else {
			return (
				<div></div>
			);
		}
	}
}

const mapStateToProps = state => ({
	loading: state.loading
});

export default connect(mapStateToProps)(Loading);
