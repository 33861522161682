import { SHOW_LOADING, HIDE_LOADING } from "../types";

const initialState = {
	showLoader: false
};

function loadingReducer(state = initialState, action) {
	switch(action.type) {
		case SHOW_LOADING:
			return {
				showLoader: true
			};

		case HIDE_LOADING:
			return {
				showLoader: false
			};

		default:
			return state;
	}
}

export default loadingReducer;
