import React from 'react';
import {connect} from "react-redux";
import {HIDE_LOADING, SHOW_LOADING} from "../../types";
import axios from 'axios';
import Toastr from 'reactjs-toastr';
import {Link} from 'react-router-dom';
import formatDate from "../../utils/formatDate";
import toJSON from "../../utils/formToJson";

class CourierTrack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            token: ''
        };
    }

    componentDidMount() {
        const {token} = this.props.match.params;

        this.setState({
            token: token
        });

        this.props.dispatch({
            type: SHOW_LOADING,
        });

        axios.get('/deliveries/token/' + token + '/')
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        packages: [response.data]
                    });
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response !== undefined) {
                    if (error.response.status === 404) {
                        Toastr.warning(error.response.data.message, 'Hmm 🤔');
                    } else {
                        Toastr.warning(error.response.data.message, 'Hmm 🤔');
                        this.props.history.push('/');
                    }
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING,
                });
            });
    }

    deliverButton() {
        return (
            <td colSpan="2" className="text-right"><Link to={'/complete/' + this.state.token}
                                                         className="btn btn-success">Complete</Link></td>
        );
    }

    render() {
        return (
            <div className="container" style={{maxWidth: "800px"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="page-content-header">
                                <h4 className="page-content-title" style={{display: "inline-block"}}>Package
                                    Details</h4>
                                <Link to="/packages" className="btn btn-blue float-right">Back To List</Link>
                                <div className="clearfix"></div>
                            </div>
                            {
                                this.state.packages.map(item => {
                                    let pickupDate = new Date(item.pickupDate);
                                    let deliverDate = new Date(item.deliverDate);
                                    let deliveredAt = item.deliveredAt ? new Date(item.deliveredAt) : '';
                                    return (
                                        <div className="table-wrapper" key={item.id}>
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <td>Sender Name:</td>
                                                    <td>{item.senderContact.fullname}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sender Address:</td>
                                                    <td dangerouslySetInnerHTML={{__html: item.recieverAddress.fullAddress}}></td>
                                                </tr>
                                                <tr>
                                                    <td>Receiver Name:</td>
                                                    <td>{item.recieverContact.fullname}</td>
                                                </tr>
                                                <tr>
                                                    <td>Receiver Address:</td>
                                                    <td dangerouslySetInnerHTML={{__html: item.recieverAddress.fullAddress}}></td>
                                                </tr>
                                                <tr>
                                                    <td>Pickup Date:</td>
                                                    <td>{formatDate(pickupDate)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Delivery Date:</td>
                                                    <td>{formatDate(deliverDate)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Service Type:</td>
                                                    <td>{item.serviceType}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tracking Number:</td>
                                                    <td>{item.trackId}</td>
                                                </tr>
                                                <tr className="hide-print">
                                                    <td>Received By:</td>
                                                    <td>{item.deliveredTo || '-'}</td>
                                                </tr>
                                                <tr className="hide-print">
                                                    <td>Delivered At:</td>
                                                    <td>{deliveredAt ? formatDate(deliveredAt) : '-'}</td>
                                                </tr>
                                                <tr>
                                                    {item.status === 'Delivered' ? null : this.deliverButton()}
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(CourierTrack);
