import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {HIDE_LOADING, SHOW_LOADING} from "../../../../types";
import axios from 'axios';
import Toastr from 'reactjs-toastr';
import toJSON from "../../../../utils/formToJson";

class CompanyCreate extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            companies: []
        };
    }

    componentDidMount() {
        this.props.dispatch({
            type: SHOW_LOADING
        });
        axios.get('/companies/')
            .then(response => {
                this.setState({
                    companies: response.data.items
                })
            })
            .catch(error => {
                if (error && error.response) {
                    Toastr.error(error.response.data.message, 'Sorry');
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING
                });
            });
    }

    onSubmit(event) {
        event.preventDefault();
        const {id} = this.props.match.params;
        const form = document.getElementById('company-form');
        const formData = toJSON(form);
        this.props.dispatch({
            type: SHOW_LOADING,
        });
        axios.post('/companies/', formData)
            .then(response => {
                if (response.status === 201) {
                    Toastr.success(response.data.message, 'Success 👍🏻');
                    this.props.history.push('/companies/' + response.data.resource.id);
                }
                if (response.status === 200) {
                    if (response.data.violations) {
                        document.querySelectorAll('input').forEach(item => {
                            item.removeAttribute('style');
                        });
                        document.querySelectorAll('.input-error').forEach(item => {
                            item.remove();
                        });
                        response.data.violations.map(item => {
                            Toastr.error(item.propertyPath + ': ' + item.title, 'Form Error');
                            document.querySelector('.input-' + item.propertyPath).style.borderWidth = '1px';
                            document.querySelector('.input-' + item.propertyPath).style.borderStyle = 'solid';
                            document.querySelector('.input-' + item.propertyPath).style.borderColor = 'darkred';
                            const errorDiv = document.createElement('div');
                            errorDiv.classList.add('input-error');
                            errorDiv.innerHTML = '<small style="color: darkred;">' + item.title + '</small>';
                            document.querySelector('.input-' + item.propertyPath).after(errorDiv);
                        })
                    }
                }
            })
            .catch(error => {
                if (error && error.response) {
                    Toastr.error(error.response.data.message, 'Sorry');
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING
                });
            });
    }

    render() {
        return (
            <div className="container" style={{maxWidth: "800px"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="page-content-header">
                                <h4 className="page-content-title" style={{display: "inline-block"}}>Create Company</h4>
                                <Link to="/companies" className="btn btn-blue float-right">Back To List</Link>
                                <div className="clearfix"></div>
                            </div>
                            <div className="table-wrapper">
                                <form id="company-form" onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" className="form-control input-name" id="name"
                                               name="name" required/>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-success" type="submit">Create</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(CompanyCreate);