import React from 'react';
import toJSON from "../../utils/formToJson";
import axios from 'axios';
import { connect } from "react-redux";
import { HIDE_LOADING, SHOW_LOADING } from "../../types";
import Toastr from 'reactjs-toastr';
import debounce from "../../utils/debounce";

class Register extends React.Component {
	constructor( props ) {
		super( props );
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onAutoSuggestClick = this.onAutoSuggestClick.bind(this);
		this.resetState = this.resetState.bind(this);
		this.searchCompany = this.searchCompany.bind(this);
		this.state = {
			companies: []
		};
	}

	componentDidMount() {
		const velocityScript = document.createElement('script');
		velocityScript.src = "/assets/js/velocity.min.js";
		const pwStrengthScript = document.createElement('script');
		pwStrengthScript.src = "/assets/js/pw_strenght.js";
		const surveyScript = document.createElement('script');
		surveyScript.src = "/assets/js/survey_func.js";

		document.body.appendChild(velocityScript);
		document.body.appendChild(pwStrengthScript);
		document.body.appendChild(surveyScript);
	}

	onAutoSuggestClick( e ) {
		e.preventDefault();
		const text = e.target.innerText;
		const targetElement = document.querySelector( e.target.getAttribute( 'data-target' ) );
		targetElement.value = text;
		this.resetState();
	}

	resetState() {
		this.setState( {
			companies: [],
		} );
	}

	onChange = debounce( ( event ) => {
		if (event.target.value != '') {
			const searchData = { name: event.target.value };
			this.searchCompany( searchData );
		} else {
			this.resetState();
		}
	}, 300 );

	searchCompany( searchData ) {
		axios.post( '/companies/search/', searchData )
			.then( response => {
				this.setState( {
					companies: response.data.map( item => {
						return item.name;
					} ),
				} );
			} )
			.catch( error => {
				if ( error && error.response && error.response.status !== 404 ) {
					if (error.response.status === 404) {
						this.resetState();
					} else {
						Toastr.error( error.response.data.message, 'Sorry' );
					}
				}
			} )
			.finally();
	}

	handleSubmit(event) {
		event.preventDefault();
		const form = document.getElementById('wrapped register-form');
		const formData = toJSON(form);
		this.props.dispatch({
			type: SHOW_LOADING,
		});
		axios.post('/authentication/register/', formData)
			.then(response => {
				if (response.status === 201) {
					Toastr.success(response.data.message, 'Success 👍🏻');
					Toastr.warning('Your account will be approved after review.', 'Reminder');
					this.props.history.push('/');
				} else if (response.data.violations) {
					response.data.violations.map(item => {
						Toastr.error(item.propertyPath + ': ' + item.title, 'Form Error')
					});
				}
			})
			.catch(error => {
				if (error && error.response) {
					Toastr.error(error.response.data.message, 'Sorry');
				}
			})
			.finally(
				this.props.dispatch({
					type: HIDE_LOADING,
				})
			);
	}

	render() {
		return (
			<div className="container-fluid full-height">
				<div className="row row-height">
					<div className="col-lg-6 content-left">
						<div className="content-left-wrapper">
							<div>
								<figure><img src="assets/img/register/van-messenger.png" alt="" className="img-fluid" /></figure>
								<h2>Stay in Control With All Your Deliveries</h2>
								<p>Do you schedule deliveries frequently and need a system that will do the work for you? You’ve come to the right place! With your Manhattan Business Services account, you can quickly place orders and track all of your packages with real-time updates.</p>
							</div>
							<div className="copy">© 2019 - Manhattan Business Services</div>
						</div>
					</div>

					<div className="col-lg-6 content-right" id="start">
						<div id="wizard_container">
							<div id="top-wizard">
								<div id="progressbar"></div>
							</div>
							<form id="wrapped register-form" onSubmit={this.handleSubmit}>
								<div id="middle-wizard">
									<div className="step">
										<h2>Register</h2>
										<h3 className="main_question"><strong>1/2</strong>Please fill with your details</h3>
										<div className="form-group">
											<input type="text" name="fullname" className="form-control required" placeholder="Full Name" />
										</div>
										<div className="form-group">
											<input type="text" name="company_name" className="form-control required"
														 placeholder="Company Name"
														 autocomplete="no" onChange={ ( event ) => {
												event.persist();
												this.onChange( event )
											} }
											/>
											{
												this.state.companies.length > 0 ? (
													<div className="suggestions">
														<ul>
															{
																this.state.companies.map( item => {
																	return (
																		<li><a href="#" data-field="content" data-target="[name=company_name]"
																					 onClick={ this.onAutoSuggestClick }>{ item }</a></li>
																	)
																} )
															}
														</ul>
													</div>
												) : null
											}
										</div>
										<div className="form-group">
											<input type="text" name="phone" className="form-control required" placeholder="Phone" />
										</div>
										<div className="form-group">
											<input type="email" name="email" className="form-control required" placeholder="Email" />
										</div>
										<div className="form-group">
											<input type="password" name="password" id="password1" className="form-control required"
														 placeholder="Password" />
										</div>
										<div className="form-group">
											<input type="password" name="password_confirm" id="password2" className="form-control required"
														 placeholder="Confirm Password" />
										</div>
										<div className="form-group">
											<div id="pass-info"></div>
										</div>
										<div className="form-group terms">
											<label className="container_check">Please accept our <a href="#" data-toggle="modal"
																																							data-target="#terms-txt">Terms and
												conditions</a>
												<input type="checkbox" name="terms" value="Yes" className="required" />
												<span className="checkmark"></span>
											</label>
										</div>
									</div>
									<div className="submit step">
										<h3 className="main_question"><strong>2/2</strong>Please fill with company details.</h3>
										<div className="form-group">
											<div className="styled-select clearfix">
												{/* eslint-disable-next-line no-undef */ }
												<select className="wide form-control required" name="state"
																onChange="getVals( this, 'state' )">
													<option value="">Select State</option>
													<option value="Alabama">Alabama</option>
													<option value="Alaska">Alaska</option>
													<option value="Arizona">Arizona</option>
													<option value="Arkansas">Arkansas</option>
													<option value="California">California</option>
													<option value="Colorado">Colorado</option>
													<option value="Connecticut">Connecticut</option>
													<option value="Delaware">Delaware</option>
													<option value="Florida">Florida</option>
													<option value="Georgia">Georgia</option>
													<option value="Hawaii">Hawaii</option>
													<option value="Idaho">Idaho</option>
													<option value="Illinois">Illinois</option>
													<option value="Indiana">Indiana</option>
													<option value="Iowa">Iowa</option>
													<option value="Kansas">Kansas</option>
													<option value="Kentucky">Kentucky</option>
													<option value="Louisiana">Louisiana</option>
													<option value="Maine">Maine</option>
													<option value="Maryland">Maryland</option>
													<option value="Massachusetts">Massachusetts</option>
													<option value="Michigan">Michigan</option>
													<option value="Minnesota">Minnesota</option>
													<option value="Mississippi">Mississippi</option>
													<option value="Missouri">Missouri</option>
													<option value="Montana">Montana</option>
													<option value="Nebraska">Nebraska</option>
													<option value="Nevada">Nevada</option>
													<option value="New Hampshire">New Hampshire</option>
													<option value="New Jersey">New Jersey</option>
													<option value="New Mexico">New Mexico</option>
													<option value="New York">New York</option>
													<option value="North Carolina">North Carolina</option>
													<option value="North Dakota">North Dakota</option>
													<option value="Ohio">Ohio</option>
													<option value="Oklahoma">Oklahoma</option>
													<option value="Oregon">Oregon</option>
													<option value="Pennsylvania">Pennsylvania</option>
													<option value="Rhode Island">Rhode Island</option>
													<option value="South Carolina">South Carolina</option>
													<option value="South Dakota">South Dakota</option>
													<option value="Tennessee">Tennessee</option>
													<option value="Texas">Texas</option>
													<option value="Utah">Utah</option>
													<option value="Vermont">Vermont</option>
													<option value="Virginia">Virginia</option>
													<option value="Washington">Washington</option>
													<option value="West Virginia">West Virginia</option>
													<option value="Wisconsin">Wisconsin</option>
													<option value="Wyoming">Wyoming</option>
												</select>
											</div>
										</div>
										<div className="form-group">
											<input type="text" name="zipCode" className="form-control required" placeholder="Zip Code" />
										</div>
										<div className="form-group">
											<input type="text" name="content" className="form-control required" placeholder="Address" />
										</div>
										{/*<div className="form-group">*/}
										{/*	<input type="text" name="department" className="form-control required" placeholder="Department" />*/}
										{/*</div>*/}
										<div className="form-group">
											<input type="text" name="floor" className="form-control required" placeholder="Floor/Suite" />
										</div>
										<div className="form-group">
											<input type="text" name="city" className="form-control required" placeholder="City" />
										</div>
									</div>
								</div>
								<div id="bottom-wizard">
									<button type="button" name="backward" className="backward">Prev</button>
									<button type="button" name="forward" className="forward">Next</button>
									<button type="submit" name="process" className="submit">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	};
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(Register);
