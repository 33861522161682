import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect, HashRouter } from 'react-router-dom';
import { connect } from "react-redux";
import DashboardWrapper from "./components/Admin/DashboardWrapper/DashboardWrapper";
import Login from "./components/Login/Login";
import Register from './components/Register/Register';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Loading from './components/Loading/Loading';
import Package from "./components/Admin/Package/Package";
import Ship from "./components/Admin/Ship/Ship";
import Deliver from "./components/Admin/Deliver/Deliver";
import PackageEdit from "./components/Admin/PackageEdit/PackageEdit";
import Packages from "./components/Admin/Packages/Packages";
import Users from "./components/Admin/Users/Users";
import Couriers from "./components/Admin/Couriers/Couriers";
import UserEdit from "./components/Admin/UserEdit/UserEdit";
import User from "./components/Admin/User/User";
import UserCreate from "./components/Admin/UserCreate/UserCreate";
import Companies from "./components/Admin/Company/Companies/Companies";
import Company from "./components/Admin/Company/Show/Company";
import CompanyEdit from "./components/Admin/Company/Edit/CompanyEdit";
import CompanyCreate from "./components/Admin/Company/Create/CompanyCreate";
import Track from "./components/Track/Track";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import CourierTrack from "./components/CourierTrack/CourierTrack";
import CourierDeliver from "./components/CourierDeliver/CourierDeliver";

const isAuthenticated = localStorage.getItem('token') ? true : false;

const PrivateRoute = ({component: Component, ...rest}) => (
  <Route {...rest} render={(props) => (
    isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />
  )} />
)

function App() {
  return (
    <HashRouter>
      <Loading />
      <Header />
      <div className="App">
        <Route path='/login' component={Login} exact />
        <Route path='/forgot-password' component={ForgotPassword} exact />
        <Route path='/reset-password/:token' component={ResetPassword} exact />
        <Route path='/register' component={Register} exact />
        <Route path='/track' component={Track} exact />
        <Route path='/courier-track/:token' component={CourierTrack} exact />
        <Route path="/complete/:token" component={CourierDeliver} exact />
        <PrivateRoute path="/package/edit/:id" component={PackageEdit} exact />
        <PrivateRoute path="/package/:id" component={Package} exact />
        <PrivateRoute path="/deliver/:id" component={Deliver} exact />
        <PrivateRoute path="/ship-now" component={Ship} exact />
        <PrivateRoute path="/ship-now/:id" component={Ship} exact />
        <PrivateRoute path="/packages" component={Packages} exact />
        <PrivateRoute path="/users" component={Users} exact />
        <PrivateRoute path="/create/user" component={UserCreate} exact />
        <PrivateRoute path="/users/edit/:id" component={UserEdit} exact />
        <PrivateRoute path="/users/:id" component={User} exact />
        <PrivateRoute path="/couriers" component={Couriers} exact />
        <PrivateRoute path="/companies" component={Companies} exact />
        <PrivateRoute path="/create/company" component={CompanyCreate} exact />
        <PrivateRoute path="/companies/edit/:id" component={CompanyEdit} exact />
        <PrivateRoute path="/companies/:id" component={Company} exact />
        <PrivateRoute path="/" component={DashboardWrapper} exact />
      </div>
      <Footer/>
    </HashRouter>
  );
}

const mapStateToProps = state => {
  return state;
}

export default connect(mapStateToProps)(App);
