import React from 'react';
import toJSON from "../../utils/formToJson";
import { connect } from "react-redux";
import { SHOW_LOADING, HIDE_LOADING, SET_CURRENT_USER } from "../../types";
import axios from 'axios';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
import { Link } from "react-router-dom";

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		const {token} = this.props.match.params;
		this.onSubmit = this.onSubmit.bind(this);
		this.state = {
			token: token
		}
	}

	onSubmit(event) {
		event.preventDefault();
		var form = document.getElementById("wrapped login-form");
		var data = toJSON(form);
		this.props.dispatch({
			type: SHOW_LOADING
		});
		if (data.plainPassword != data.password_confirm) {
			toastr.error('Password doesn\'t match.', 'Error');
			this.props.dispatch({
				type: HIDE_LOADING
			});
		} else {
			axios.post( '/reset_password/change/', data ).then( response => {
				if ( response.status === 200 && !response.data.title && response.data.status !== 'failure' ) {
					toastr.success( response.data.message, 'Success' );
					this.props.history.push( '/' );
				} else {
					if ( response.data.title ) {
						toastr.error( response.data.title + ': ' + response.data.detail, 'Sorry' );
					} else {
						toastr.error( 'An error occured, please try again later.', 'Sorry' );
					}
				}
				this.props.dispatch( {
					type: HIDE_LOADING
				} );
			} ).catch( ( error ) => {
				if ( error && error.response && error.response.data ) {
					toastr.error( error.response.data.message, 'Sorry' );
				}
				this.props.dispatch( {
					type: HIDE_LOADING
				} );
			} );
		}
	}

	render() {
		return (
			<div className="container-fluid full-height">
				<div className="row row-height">
					<div className="col-lg-6 content-left">
						<div className="content-left-wrapper">
							<div>
								<figure><img src="assets/img/register/van-messenger.png" alt="" className="img-fluid" /></figure>
								<h2>You Don't Have An Account?</h2>
								<p><Link to="/register"><span style={{color: "#ffffff", textDecoration: "underline"}}>Click here</span></Link> to setup web access. You can place and track orders, run activity reports, and even view invoices.<br />
									When you login to this site, you will be able to place orders for delivery directly into Manhattan Business Services. You should be aware that until you logout, anyone who uses your computer will also have access to place orders on your account. You are responsible for all charges incurred under your User ID and Account Number.</p>
								<Link to="/register" className="btn_1 rounded">Sign Up Now</Link>
							</div>
							<div className="copy">© 2019 - Manhattan Business Services</div>
						</div>
					</div>

					<div className="col-lg-6 content-right" id="start">
						<div id="wizard_container">
							<form id="wrapped login-form" onSubmit={this.onSubmit}>
								<div id="middle-wizard">
									<div className="step submit">
										<h2>Reset Your Password</h2>
										<input type="hidden" name="token" value={this.state.token} />
										<div className="form-group">
											<input type="password" name="plainPassword" className="form-control required" placeholder="Password" />
										</div>
										<div className="form-group">
											<input type="password" name="password_confirm" className="form-control required" placeholder="Confirm Password" />
										</div>
									</div>
								</div>
								<div id="bottom-wizard">
									<button type="submit" name="process" className="submit">Reset Password</button>
								</div>
							</form>
							<div id="top-wizard">
								<div id="progressbar"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	user: state.user,
	loading: state.loading,
});

export default connect(mapStateToProps)(ResetPassword);
