import React from 'react';
import toJSON from "../../utils/formToJson";
import { connect } from "react-redux";
import { SHOW_LOADING, HIDE_LOADING, SET_CURRENT_USER } from "../../types";
import axios from 'axios';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import { Link } from "react-router-dom";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(event) {
		event.preventDefault();
		var form = document.getElementById("wrapped login-form");
		var data = toJSON(form);
		this.props.dispatch({
			type: SHOW_LOADING
		});
		axios.post('/authentication/login_check/', data).then(response => {
			if (response.status === 200 && response.data.status !== 'failure') {
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('user', JSON.stringify(response.data.data.user));
				this.props.dispatch({
					type: SET_CURRENT_USER,
					payload: {
						user: response.data.user
					}
				});
				setAuthorizationToken(response.data.token);
				toastr.success('You logged in successfully.');
				window.location.href = '/';
			} else {
				if (response.data.status === 'failure') {
					toastr.error( response.data.message, 'Sorry' );
				} else {
					toastr.error( 'An error occured, please try again later.', 'Sorry' );
				}
			}
			this.props.dispatch({
				type: HIDE_LOADING
			});
		}).catch((error) => {
			if (error.response) {
				if ( error.response.status === 401 ) {
					console.log( 'Sorry man!' );
					toastr.error( error.response.data.message, 'Sorry' );
				}
			}
			this.props.dispatch( {
				type: HIDE_LOADING
			} );
		});
	}

	render() {
		return (
			<div className="container-fluid full-height">
				<div className="row row-height">
					<div className="col-lg-6 content-left">
						<div className="content-left-wrapper">
							<div>
								<figure><img src="assets/img/register/van-messenger.png" alt="" className="img-fluid" /></figure>
								<h2>You Don't Have An Account?</h2>
								<p><Link to="/register"><span style={{color: "#ffffff", textDecoration: "underline"}}>Click here</span></Link> to setup web access. You can place and track orders, run activity reports, and even view invoices.<br />
									When you login to this site, you will be able to place orders for delivery directly into Manhattan Business Services. You should be aware that until you logout, anyone who uses your computer will also have access to place orders on your account. You are responsible for all charges incurred under your User ID and Account Number.</p>
								<Link to="/register" className="btn_1 rounded">Sign Up Now</Link>
							</div>
							<div className="copy">© 2019 - Manhattan Business Services</div>
						</div>
					</div>

					<div className="col-lg-6 content-right" id="start">
						<div id="wizard_container">
							<form id="wrapped login-form" onSubmit={this.onSubmit}>
								<div id="middle-wizard">
									<div className="step submit">
										{/*<h2>Login</h2>*/}
										<p className="text-center">
											<img src="assets/img/logo_dark.png" alt="Logo" />
										</p>
										<h5 className="main_question">Please enter your login information to continue</h5>
										<p>
											Internet delivery orders are only accepted M-F 8am-6pm, all other times call 212.481.1007
										</p>
										<div className="form-group">
											<input type="email" name="username" className="form-control required" placeholder="Email" />
										</div>
										<div className="form-group">
											<input type="password" name="password" className="form-control required" placeholder="Password" />
										</div>
										<div className="form-group">
											<Link to="/forgot-password">Forgot Your Password?</Link>
										</div>
										{/*<div className="form-group terms">*/}
										{/*	<label className="container_check">Remember Me*/}
										{/*		<input type="checkbox" name="remember_me" className="required" />*/}
										{/*		<span className="checkmark"></span>*/}
										{/*	</label>*/}
										{/*</div>*/}
									</div>
								</div>
								<div id="bottom-wizard">
									<button type="submit" name="process" className="submit">Login</button>
								</div>
								<div style={{marginTop: "15px"}}>
									<p>
										<small>*MANHATTAN BUSINESS SERVICES do not take responsibility for cash, jewelry, or securities. All valued items must be declared for acceptance and an additional fee paid for extra insurance. The maximum liability for lost or damaged packages is limited to $100.00, unless otherwise declared. All Truck movements are automatically insured up to $400 at an additional charge of 50 cents per delivery. Additional insurance is available at 2% per hundred.</small>
									</p>
								</div>
							</form>
							<div id="top-wizard">
								<div id="progressbar"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	user: state.user,
	loading: state.loading,
});

export default connect(mapStateToProps)(Login);
