import React from 'react';
import {connect} from "react-redux";
import {HIDE_LOADING, SHOW_LOADING} from "../../../types";
import axios from 'axios';
import Toastr from 'reactjs-toastr';
import {Link} from 'react-router-dom';
import formatDate from "../../../utils/formatDate";
import toJSON from "../../../utils/formToJson";

class Package extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            couriers: [],
        };
        this.submitUpdateForm = this.submitUpdateForm.bind(this);
        this.courierField = this.courierField.bind(this);
        this.editLink = this.editLink.bind(this);
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        this.props.dispatch({
            type: SHOW_LOADING,
        });

        axios.get('/deliveries/' + id + '/')
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        packages: [response.data]
                    });
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response !== undefined) {
                    if (error.response.status === 404) {
                        Toastr.warning(error.response.data.message, 'Hmm 🤔');
                    } else {
                        Toastr.warning(error.response.data.message, 'Hmm 🤔');
                        this.props.history.push('/');
                    }
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING,
                });
            });

        const style = document.createElement('style');
        style.textContent = "@media print {header, footer, .btn, .hide-print {display: none;}} @page {size: auto; margin: 0mm;}";
        document.body.appendChild(style);

        const user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.indexOf('ROLE_ADMIN') !== -1) {
            this.props.dispatch( {
                type: SHOW_LOADING
            } );
            axios.get( '/users/?roles=ROLE_COURIER' )
              .then( response => {
                  if ( response.status === 200 ) {
                      this.setState( {
                          couriers: response.data
                      } )
                  }
              } )
              .catch( error => {
                  if ( error && error.response ) {
                      Toastr.error( error.response.data.message, 'Sorry' );
                  }
              } )
              .finally( () => {
                  this.props.dispatch( {
                      type: HIDE_LOADING
                  } );
              } );
        }
    }

    printPage() {
        window.print();
    }

    submitUpdateForm(e) {
        e.preventDefault();
        var form = document.getElementById('updateForm');
        var formData = toJSON(form);
        var courierId = document.getElementById('courier').value;
        formData.courier = courierId;
        const {id} = this.props.match.params;
        this.props.dispatch({
            type: SHOW_LOADING
        });
        axios.put('/deliveries/' + id + '/', formData)
            .then(response => {
                if (response.status === 200) {
                    Toastr.success(response.data.message, 'Success');
                    this.props.history.push('/packages');
                }
            })
            .catch(error => {
                if (error && error.response) {
                    Toastr.error(error.response.data.message, 'Sorry');
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING
                });
            });
    }

    updateButton() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.indexOf('ROLE_ADMIN') !== -1) {
            return (
                <button className="btn btn-warning float-left" type="submit" form="updateForm">Update Package</button>);
        }
    }

    courierField(item) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.indexOf('ROLE_ADMIN') !== -1) {
            return (<tr>
                <td>Courier:</td>
                <td>
                    <select name="courier" id="courier">
                        <option value="0">Select Courier</option>
                        {this.state.couriers.map(courier => {
                            return (<option value={courier.id} key={courier.id} selected={courier.id == (item.courier ? item.courier.id : 0)}>{courier.fullname}</option>)
                        })}
                    </select>
                </td>
            </tr>)
        }
    }

    statusForm(item) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.indexOf('ROLE_ADMIN') !== -1) {
            return (
                <tbody className="hide-print">
                <tr>
                    <td>Current Status:</td>
                    <td>
                        <form id="updateForm" onSubmit={this.submitUpdateForm}>
                            <select name="status" id="current-status" defaultValue={item.status}>
                                <option value="Pending">Pending</option>
                                <option value="Assigned To Courier">Assigned To The Courier</option>
                                <option value="Out For Delivery">Out For Delivery</option>
                                <option value="Delivered">Delivered</option>
                                <option value="Returned">Returned</option>
                            </select>
                        </form>
                    </td>
                </tr>
                {this.courierField(item)}
                </tbody>
            );
        }
    }

    editLink(item) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.indexOf('ROLE_ADMIN') !== -1) {
            return (
                <Link to={'/deliver/' + item.id}><small>(Edit)</small></Link>
            );
        }
    }

    render() {
        return (
            <div className="container" style={{maxWidth: "800px"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="page-content-header">
                                <h4 className="page-content-title" style={{display: "inline-block"}}>Package
                                    Details</h4>
                                <Link to="/packages" className="btn btn-blue float-right">Back To List</Link>
                                <div className="clearfix"></div>
                            </div>
                            {
                                this.state.packages.map(item => {
                                    let pickupDate = new Date(item.pickupDate);
                                    let deliverDate = new Date(item.deliverDate);
                                    let deliveredAt = item.deliveredAt ? new Date(item.deliveredAt) : '';
                                    return (
                                        <div className="table-wrapper" key={item.id}>
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <td colspan="2"><b>From:</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Company:</td>
                                                    <td>{item.senderContact.companyName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name:</td>
                                                    <td>{item.senderContact.fullname}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address:</td>
                                                    <td dangerouslySetInnerHTML={{__html: item.senderAddress.fullAddress}}></td>
                                                </tr>
                                                <tr>
                                                    <td>Phone:</td>
                                                    <td>{item.senderContact.phone || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{item.senderContact.email || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><b>To:</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Company:</td>
                                                    <td>{item.recieverContact.companyName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name:</td>
                                                    <td>{item.recieverContact.fullname}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address:</td>
                                                    <td dangerouslySetInnerHTML={{__html: item.recieverAddress.fullAddress}}></td>
                                                </tr>
                                                <tr>
                                                    <td>Phone:</td>
                                                    <td>{item.recieverContact.phone || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{item.recieverContact.email || '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2"><b>Package Details</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Pickup Date:</td>
                                                    <td>{formatDate(pickupDate)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Delivery Date:</td>
                                                    <td>{formatDate(deliverDate)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Service Type:</td>
                                                    <td>{item.serviceType}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tracking Number:</td>
                                                    <td>{item.trackId}</td>
                                                </tr>
                                                <tr className="hide-print">
                                                    <td>Received By:</td>
                                                    <td>{item.deliveredTo || '-'} {this.editLink(item)}</td>
                                                </tr>
                                                <tr className="hide-print">
                                                    <td>Delivered At:</td>
                                                    <td>{deliveredAt ? formatDate(deliveredAt) : '-'} {this.editLink(item)}</td>
                                                </tr>
                                                </tbody>
                                                {this.statusForm(item)}
                                            </table>
                                            {this.updateButton()}
                                            <button className="btn btn-success float-right"
                                                    onClick={this.printPage}>Print
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(Package);
