import React from 'react';

function Footer() {
	return (
		<footer>
			<div className="container">
				<p className="text-align: center;">Made with <img src="/assets/img/pixel_heart.png" alt="❤️"
																													style={{ width: '20px'}} /> by <a
					href="https://moreeffectstudios.com/">More Effect Studios</a></p>
			</div>
		</footer>
	);
}

export default Footer;
