import React from 'react';
import {HIDE_LOADING, SHOW_LOADING} from "../../../types";
import axios from "axios";
import toastr from "reactjs-toastr";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        };
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        this.props.dispatch({
            type: SHOW_LOADING,
        });

        axios.get('/users/' + id + '/')
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        users: [response.data]
                    });
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response !== undefined) {
                    if (error.response.status === 404) {
                        toastr.warning(error.response.data.message, 'Hmm 🤔');
                    } else {
                        toastr.warning(error.response.data.message, 'Hmm 🤔');
                        this.props.history.push('/');
                    }
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING,
                });
            });
    }

    render() {
        return (
            <div className="container" style={{maxWidth: "800px"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="page-content-header">
                                <h4 className="page-content-title" style={{display: "inline-block"}}>User
                                    Details</h4>
                                <Link to="/users" className="btn btn-blue float-right">Back To List</Link>
                                <div className="clearfix"></div>
                            </div>
                            {
                                this.state.users.map(item => {
                                    let pickupDate = new Date(item.pickupDate);
                                    let deliverDate = new Date(item.deliverDate);
                                    return (
                                        <div className="table-wrapper">
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <td>Full Name:</td>
                                                    <td>{item.fullname}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email Activation:</td>
                                                    <td>{item.isActive === true ? 'Active' : 'Not Active'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Company:</td>
                                                    <td>{item.company ? item.company.name : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{item.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone:</td>
                                                    <td>{item.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td>Roles:</td>
                                                    <td>{{'ROLE_USER': 'User', 'ROLE_ADMIN': 'Admin', 'ROLE_COURIER': 'Courier'}[item.roles[0]]}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(User);