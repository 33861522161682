import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import isAuthenticated from "../../utils/isAuthenticated";
import {connect} from "react-redux";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.onLogout = this.onLogout.bind(this);
	}

	onLogout() {
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		window.location.href = '/';
	}

	resources() {
		return (
			<li className="dropdown">
				<a href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Resources</a>
				<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<li><a href="/assets/docs/account_application.pdf" target="_blank">Account Application</a></li>
					<li><a href="/assets/docs/credit_card_authorization_form.pdf" target="_blank">Credit Card Authorization Form</a></li>
					<li><a href="/assets/docs/terms_of_service.pdf" target="_blank">Terms Of Services</a></li>
					<li><a href="/assets/docs/brochure.pdf" target="_blank" style={{textAlign: "center"}}>Brochure</a></li>
				</ul>
			</li>
		);
	}

	returnToHome(e) {
		e.preventDefault();
		if (localStorage.getItem('user')) {
			// eslint-disable-next-line no-restricted-globals
			if ( confirm( 'You didn\'t logged out, are you sure want to go to homepage?' ) ) {
				window.location.href = 'https://manhattanbusinessservices.com';
			} else {
				return false;
			}
		} else {
			window.location.href = 'https://manhattanbusinessservices.com';
		}
	}

	render() {
		if (!isAuthenticated()) {
			return (
				<header>
					<div className="container-fluid">
						<div className="row">
							<div className="col-3 logo-wrapper">
								<Link to="/"><img src="assets/img/logo_dark.png" alt="" width="200" height="35" /></Link>
							</div>
							<div className="col-9 col">
								<div className="header-menu">
									<ul>
										<li><a href="https://manhattanbusinessservices.com" onClick={this.returnToHome}>Return To Home</a></li>
										{this.resources()}
										<li><NavLink exact activeClassName='active' to='/register'>Register</NavLink></li>
										<li><NavLink exact activeClassName='active' to='/login'>Login</NavLink></li>
										<li><NavLink exact activeClassName='active' to='/track'>Track</NavLink></li>
									</ul>
									<a href="#" className="cd-nav-trigger">Menu<span className="cd-icon"></span></a>
								</div>
								<nav>
									<ul className="cd-primary-nav">
										<li><a href="https://manhattanbusinessservices.com" onClick={this.returnToHome}>Return To Home</a></li>
										{this.resources()}
										<li><NavLink exact activeClassName='active' to='/register'>Register</NavLink></li>
										<li><NavLink exact activeClassName='active' to='/login'>Login</NavLink></li>
										<li><NavLink exact activeClassName='active' to='/track'>Track</NavLink></li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</header>
			);
		} else {
			const user = JSON.parse(localStorage.getItem('user'));
			if (user.roles.indexOf('ROLE_ADMIN') !== -1) {
				return (
					<header>
						<div className="container-fluid">
							<div className="row">
								<div className="col-3 logo-wrapper">
									<Link to="/"><img src="assets/img/logo_dark.png" alt="" width="200" height="35" /></Link>
								</div>
								<div className="col-9 col">
									<div className="header-menu">
										<ul>
											<li><a href="https://manhattanbusinessservices.com" onClick={this.returnToHome}>Return To Home</a></li>
											{this.resources()}
											<li><NavLink exact activeClassName='active' to='/'>Dashboard</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/packages'>Packages</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/users'>Users</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/couriers'>Couriers</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/companies'>Companies</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/ship-now'>Ship Now</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/track'>Track</NavLink></li>
											<li><a href="#" onClick={this.onLogout}>Logout</a></li>
										</ul>
										<a href="#" className="cd-nav-trigger">Menu<span className="cd-icon"></span></a>
									</div>
									<nav>
										<ul className="cd-primary-nav">
											<li><a href="https://manhattanbusinessservices.com" onClick={this.returnToHome}>Return To Home</a></li>
											{this.resources()}
											<li><NavLink exact activeClassName='active' to='/'>Awaiting Packages</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/packages'>Packages</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/users'>Users</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/couriers'>Couriers</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/companies'>Companies</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/ship-now'>Ship Now</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/track'>Track</NavLink></li>
											<li><a href="#" onClick={this.onLogout}>Logout</a></li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</header>
				);
			} else if (user.roles.indexOf('ROLE_COURIER') !== -1) {
				return (
					<header>
						<div className="container-fluid">
							<div className="row">
								<div className="col-3 logo-wrapper">
									<Link to="/"><img src="assets/img/logo_dark.png" alt="" width="200" height="35" /></Link>
								</div>
								<div className="col-9 col">
									<div className="header-menu">
										<ul>
											<li><a href="https://manhattanbusinessservices.com" onClick={this.returnToHome}>Return To Home</a></li>
											<li><NavLink exact activeClassName='active' to='/'>My Packages</NavLink></li>
											<li><a href="#" onClick={this.onLogout}>Logout</a></li>
										</ul>
										<a href="#" className="cd-nav-trigger">Menu<span className="cd-icon"></span></a>
									</div>
									<nav>
										<ul className="cd-primary-nav">
											<li><a href="https://manhattanbusinessservices.com" onClick={this.returnToHome}>Return To Home</a></li>
											<li><NavLink exact activeClassName='active' to='/'>My Packages</NavLink></li>
											<li><a href="#" onClick={this.onLogout}>Logout</a></li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</header>
				);
			} else {
				return (
					<header>
						<div className="container-fluid">
							<div className="row">
								<div className="col-3 logo-wrapper">
									<Link to="/"><img src="assets/img/logo_dark.png" alt="" width="200" height="35" /></Link>
								</div>
								<div className="col-9 col">
									<div className="header-menu">
										<ul>
											<li><a href="https://manhattanbusinessservices.com" onClick={this.returnToHome}>Return To Home</a></li>
											{this.resources()}
											<li><NavLink exact activeClassName='active' to='/'>Package History</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/ship-now'>Ship Now</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/track'>Track</NavLink></li>
											<li><a href="#" onClick={this.onLogout}>Logout</a></li>
										</ul>
										<a href="#" className="cd-nav-trigger">Menu<span className="cd-icon"></span></a>
									</div>
									<nav>
										<ul className="cd-primary-nav">
											<li><a href="https://manhattanbusinessservices.com" onClick={this.returnToHome}>Return To Home</a></li>
											{this.resources()}
											<li><NavLink exact activeClassName='active' to='/'>Package History</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/ship-now'>Ship Now</NavLink></li>
											<li><NavLink exact activeClassName='active' to='/track'>Track</NavLink></li>
											<li><a href="#" onClick={this.onLogout}>Logout</a></li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</header>
				);
			}
		}
	}
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(Header);
