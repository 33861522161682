import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {HIDE_LOADING, SHOW_LOADING} from "../../../types";
import axios from 'axios';
import Toastr from 'reactjs-toastr';
import toJSON from "../../../utils/formToJson";

class UserCreate extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            companies: []
        };
    }

    componentDidMount() {
        this.props.dispatch({
            type: SHOW_LOADING
        });
        axios.get('/companies/')
            .then(response => {
                this.setState({
                    companies: response.data.items
                })
            })
            .catch(error => {
                if (error && error.response) {
                    Toastr.error(error.response.data.message, 'Sorry');
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING
                });
            });
    }

    onSubmit(event) {
        event.preventDefault();
        const {id} = this.props.match.params;
        const form = document.getElementById('user-form');
        const formData = toJSON(form);
        this.props.dispatch({
            type: SHOW_LOADING,
        });
        const passwordField = document.getElementById('password');
        const passwordConfirmField = document.getElementById('confirm_password');
        if (passwordField.value != '') {
            if (passwordField.value != passwordConfirmField.value) {
                alert('Passwords are not matching.');
                this.props.dispatch({
                    type: HIDE_LOADING
                });
                return;
            }
            formData.plainPassword = passwordField.value;
        } else {
            alert('Please set password');
            return;
        }
        if (formData.roles) {
            formData.roles = [formData.roles];
        }
        axios.post('/users/', formData)
            .then(response => {
                if (response.status === 201) {
                    Toastr.success(response.data.message, 'Success 👍🏻');
                    this.props.history.push('/users/' + response.data.resource.id);
                }
                if (response.status === 200) {
                    if (response.data.violations) {
                        document.querySelectorAll('input').forEach(item => {
                            item.removeAttribute('style');
                        });
                        document.querySelectorAll('.input-error').forEach(item => {
                            item.remove();
                        });
                        response.data.violations.map(item => {
                            Toastr.error(item.propertyPath + ': ' + item.title, 'Form Error');
                            document.querySelector('.input-' + item.propertyPath).style.borderWidth = '1px';
                            document.querySelector('.input-' + item.propertyPath).style.borderStyle = 'solid';
                            document.querySelector('.input-' + item.propertyPath).style.borderColor = 'darkred';
                            const errorDiv = document.createElement('div');
                            errorDiv.classList.add('input-error');
                            errorDiv.innerHTML = '<small style="color: darkred;">' + item.title + '</small>';
                            document.querySelector('.input-' + item.propertyPath).after(errorDiv);
                        })
                    }
                }
            })
            .catch(error => {
                if (error && error.response) {
                    Toastr.error(error.response.data.message, 'Sorry');
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING
                });
            });
    }

    RoleInput() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.indexOf('ROLE_ADMIN') != -1) {
            return (
                <div className="form-group">
                    <label htmlFor="roles">Role</label>
                    <select name="roles" className="form-control" id="roles">
                        <option value="ROLE_USER" key="1">User</option>
                        <option value="ROLE_COURIER" key="2">Courier</option>
                        <option value="ROLE_ADMIN" key="3">Admin</option>
                    </select>
                </div>
            );
        }

        return (<div></div>);
    }

    CompanyInput() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.indexOf('ROLE_ADMIN') != -1) {
            if (this.state.companies != []) {
                return (
                    <div className="form-group">
                        <label htmlFor="company">Company</label>
                        <select name="company" className="form-control" id="company">
                            <option value="" key="0">-</option>
                            {
                                this.state.companies.map(item => {
                                    return (
                                        <option value={item.id} key={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                );
            }
        }

        return (<div></div>);
    }

    render() {
        return (
            <div className="container" style={{maxWidth: "800px"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="page-content-header">
                                <h4 className="page-content-title" style={{display: "inline-block"}}>Create User</h4>
                                <Link to="/users" className="btn btn-blue float-right">Back To List</Link>
                                <div className="clearfix"></div>
                            </div>
                            <div className="table-wrapper">
                                <form id="user-form" onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="fullname">Full Name</label>
                                        <input type="text" className="form-control input-fullname" id="fullname"
                                               name="fullname" required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" className="form-control input-email" id="email"
                                               name="email"
                                               required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone</label>
                                        <input type="text" className="form-control input-phone" id="phone"
                                               name="phone"
                                               required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password <small>(Enter a password if you want to
                                            change current)</small></label>
                                        <input type="password" className="form-control input-plainPassword"
                                               id="password" required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password_confirm">Confirm Password</label>
                                        <input type="password" className="form-control" id="confirm_password" required/>
                                    </div>
                                    {this.RoleInput()}
                                    {this.CompanyInput()}
                                    <div className="form-group">
                                        <button className="btn btn-success" type="submit">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(UserCreate);