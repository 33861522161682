import React from 'react';
import {HIDE_LOADING, SHOW_LOADING} from "../../../../types";
import axios from "axios";
import toastr from "reactjs-toastr";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: {}
        };
    }

    componentDidMount() {
        const {id} = this.props.match.params;

        this.props.dispatch({
            type: SHOW_LOADING,
        });

        axios.get('/companies/' + id + '/')
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        company: response.data
                    });
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response !== undefined) {
                    if (error.response.status === 404) {
                        toastr.warning(error.response.data.message, 'Hmm 🤔');
                    } else {
                        toastr.warning(error.response.data.message, 'Hmm 🤔');
                        this.props.history.push('/');
                    }
                }
            })
            .finally(() => {
                this.props.dispatch({
                    type: HIDE_LOADING,
                });
            });
    }

    render() {
        return (
            <div className="container" style={{maxWidth: "800px"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">
                            <div className="page-content-header">
                                <h4 className="page-content-title" style={{display: "inline-block"}}>Company
                                    Details</h4>
                                <Link to="/companies" className="btn btn-blue float-right">Back To List</Link>
                                <div className="clearfix"></div>
                            </div>
                            <div className="table-wrapper">
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>Name:</td>
                                        <td>{this.state.company.name}</td>
                                    </tr>
                                    {
                                        this.state.company.addresses ? this.state.company.addresses.map(address => {
                                            return (
                                                <tr key={address.id}>
                                                    <td>Address:</td>
                                                    <td dangerouslySetInnerHTML={{__html: address.fullAddress}}></td>
                                                </tr>
                                            )
                                        }) : null
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(Company);