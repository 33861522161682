import React from 'react';
import axios from 'axios';
import toastr from "reactjs-toastr";
import {HIDE_LOADING, SHOW_LOADING} from "../../../types";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

class Packages extends React.Component {
	constructor(props) {
		super(props);

		this.state = {packages: []};
	}

	componentDidMount() {
		this.props.dispatch({
			type: SHOW_LOADING,
		});

		axios.get('/deliveries/')
			.then(response => {
				this.setState({
					packages: response.data
				});
				this.props.dispatch({
					type: HIDE_LOADING,
				});
			})
			.catch(error => {
				if (error.response !== undefined) {
					if (error.response.status === 404) {
						toastr.warning(error.response.data.message, 'Hmm 🤔');
					} else {
						toastr.error(error.response.data.message, 'Hmm 🤔');
					}
				}
				this.props.dispatch({
					type: HIDE_LOADING,
				});
			});
	}

	fetchPackages() {
		this.setState({packages: [{id: 1, title: 'test'}, {id: 2, title: 'test 2'}]});
	}

	render() {
		var user = JSON.parse( localStorage.getItem( 'user' ) );
		if ( user.roles.indexOf( 'ROLE_ADMIN' ) !== - 1 ) {
			return (
				<div>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="page-content">
									<div className="page-content-header">
										<h4 className="page-content-title" style={ { display: "inline-block" } }>Packages</h4>
										<div className="clearfix"></div>
									</div>
									<div className="table-wrapper">
										<table className="table table-striped table-hover">
											<thead>
											<tr>
												<th>#</th>
												<th>Pickup Date</th>
												<th>Deliver Date</th>
												<th>From</th>
												<th>To</th>
												<th>Status</th>
												<th>Actions</th>
											</tr>
											</thead>
											<tbody>
											<tr></tr>
											{
												this.state.packages.map(item => {
													let pickupDate = new Date(item.pickupDate);
													let deliverDate = new Date(item.deliverDate);
													return (
														<tr key={item.id}>
															<td>{item.id}</td>
															<td>{formatDate(pickupDate)}</td>
															<td>{formatDate(deliverDate)}</td>
															<td>{item.senderContact.companyName}</td>
															<td>{item.recieverContact.companyName}</td>
															<td dangerouslySetInnerHTML={{__html: item.status === 'Delivered' ? "<span style='color:green'>Delivered</span>" : item.status}}></td>
															<td><Link to={"/package/" + item.id}>Details</Link>
																{/*| <Link to={"/package/edit/" + item.id}>Edit Package</Link>*/}
															</td>
														</tr>
													)
												})
											}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else if ( user.roles.indexOf( 'ROLE_COURIER' ) !== - 1 ) {
			return (
				<div>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="page-content">
									<div className="page-content-header">
										<h4 className="page-content-title" style={ { display: "inline-block" } }>Packages Assigned To Me</h4>
										<div className="clearfix"></div>
									</div>
									<div className="table-wrapper">
										<table className="table table-striped table-hover">
											<thead>
											<tr>
												<th>#</th>
												<th>Pickup Date</th>
												<th>Deliver Date</th>
												<th>From</th>
												<th>To</th>
												<th>Status</th>
												<th>Actions</th>
											</tr>
											</thead>
											<tbody>
											<tr></tr>

											{
												this.state.packages.map(item => {
													let pickupDate = new Date(item.pickupDate);
													let deliverDate = new Date(item.deliverDate);
													return (
														<tr key={item.id}>
															<td>{item.id}</td>
															<td>{formatDate(pickupDate)}</td>
															<td>{formatDate(deliverDate)}</td>
															<td>{item.senderContact.companyName}</td>
															<td>{item.recieverContact.companyName}</td>
															<td dangerouslySetInnerHTML={{__html: item.status === 'Delivered' ? "<span style='color:green'>Delivered</span>" : item.status}}></td>
															<td><Link to={"/package/" + item.id}>Details</Link> {item.status !== 'Delivered' ? '| ' : null}{item.status !== 'Delivered' ? <Link to={"/deliver/" + item.id}>Complete Delivery</Link> : null }</td>
														</tr>
													)
												})
											}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else if ( user.roles.indexOf( 'ROLE_USER') !== - 1 ) {
			return (
				<div>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="page-content">
									<div className="page-content-header">
										<h4 className="page-content-title" style={ { display: "inline-block" } }>Package History</h4>
										<Link to="/ship-now" className="btn btn-blue float-right">Ship Now</Link>
										<div className="clearfix"></div>
									</div>
									<div className="table-wrapper">
										<table className="table table-striped table-hover">
											<thead>
											<tr>
												<th>#</th>
												<th>Pickup Date</th>
												<th>Deliver Date</th>
												<th>From</th>
												<th>To</th>
												<th>Status</th>
												<th>Actions</th>
											</tr>
											</thead>
											<tbody>
											<tr></tr>

											{
												this.state.packages.map(item => {
													let pickupDate = new Date(item.pickupDate);
													let deliverDate = new Date(item.deliverDate);
													return (
														<tr key={item.id}>
															<td>{item.id}</td>
															<td>{formatDate(pickupDate)}</td>
															<td>{formatDate(deliverDate)}</td>
															<td>{item.senderContact.companyName}</td>
															<td>{item.recieverContact.companyName}</td>
															<td dangerouslySetInnerHTML={{__html: item.status === 'Delivered' ? "<span style='color:green'>Delivered</span>" : item.status}}></td>
															<td><Link to={"/package/" + item.id}>Details</Link></td>
															{/*| <a href="#">Repeat</a>*/}
														</tr>
													)
												})
											}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					Nothing to see here.
				</div>
			);
		}
	}
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(Packages);
